import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  CustomFieldsForSectionInterface,
  CustomFieldsInterface,
  SectionOptions,
} from '@src/interfaces/customFields'
import { GetRequestInterface, RequestInterfaceNew, GetRequestData } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'

export const customFieldsRequest: RequestInterfaceNew<CustomFieldsInterface> = {
  get: async ({ id }) => api.get(`${API.CUSTOM_FIELDS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.CUSTOM_FIELDS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.CUSTOM_FIELDS, data),
  delete: async ({ id }) => api.delete(`${API.CUSTOM_FIELDS}/${id}`),
}

export const customFieldsRequestTable = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<CustomFieldsInterface>> =>
  api.get(API.CUSTOM_FIELDS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getCustomFieldsForSectionRequest = (
  filters: FilterByInterface[],
): AxiosPromise<GetRequestInterface<CustomFieldsForSectionInterface>> =>
  api.get(`${API.CUSTOM_FIELDS}`, {
    params: filterSortPageIntoQuery(undefined, filters),
  })

export const useGetSectionCustomFields = (
  sectionId: SectionOptions | undefined,
  fieldNames: string[],
  uuid?: string[],
  filters?: FilterByInterface[],
) =>
  useFetch<GetRequestData<CustomFieldsForSectionInterface>>(
    sectionId ? API.CUSTOM_FIELDS : null,
    undefined,
    {
      params: {
        section__id: sectionId,
        field_names: fieldNames.join(','),
        uuid: uuid?.join(','),
        ...(filters ? filterSortPageIntoQuery(undefined, filters) : {}),
      },
    },
  )
