import { useGetReviewCycleDetails } from '@src/api/reviewCycles'
import pluralize from 'pluralize'

export const useCycleGoalsCalibrationState = (cycleId: number | string | undefined) => {
  const { data } = useGetReviewCycleDetails(cycleId)
  return {
    isActive: data?.is_goals_calibration_active ?? false,
    timeLeft:
      typeof data?.goals_calibration_days_left === 'number'
        ? pluralize('day', data.goals_calibration_days_left, true)
        : null,
  }
}
